import React from 'react'
import { graphql } from 'gatsby'

import Page from '../components/Page'
import IndexLayout from '../layouts'
import styled from '@emotion/styled'

interface PageTemplateProps {
  data: {
    markdownRemark: {
      html: string
    }
  }
}

export const query = graphql`
  query PageTemplateQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
    }
  }
`

const MarkdownDiv = styled.div`
  p {
    color: #fff;

    strong {
      color: #fff;
    }
  }
  li {
    color: #fff;
  }
  ul {
    color: #fff;
  }
  h1 h2 h3 h4 h5 h6 {
    color: #fff;
  }
  a {
    color: #fff;
  }
`

export function PageTemplate({ data }: PageTemplateProps) {
  return (
    <IndexLayout>
      <Page>
        <MarkdownDiv style={{ color: '#fff' }} dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
      </Page>
    </IndexLayout>
  )
}

export default PageTemplate
